.navigationBar {
    background-color: #DB9C7B;
    padding: 0%;
    width: 100vw;
    height: 15vh;
    position: fixed;
    top: 0px;
    z-index: 1;
    box-shadow: 0 2px 12px 0px #D24C4B;
}

#navigationBar {
    display: flex;
}

#logo {
    position: relative;
    height: 15vh;
    display: inline;
    float:left;
    width: 30vw;
    object-fit: contain;
    object-position: left;
}

#nav_text {
    display: flex;
    flex-direction: row;
        position: absolute;
    right: 0;
    width: 70vw;
}

.navigationBar button{

    position: relative;
    left: 0px;
    font-size: 2.5vw;
    font-weight: 500;
    margin: 0px auto;
    height: 15vh;
    background-color:#DB9C7B;
    color:black;
    display: inline;
    border: 0px;
}

.navigationBar button:hover{
    color:#577B57
}

.navigationBar button.selected{
    color:#577B57
}

.navigationBarPhone {
    background-color:#DB9C7B;
    height: 15vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    box-shadow: 0 2px 12px 0px #D24C4B;

} 

#logoPhone {
    position: relative;
    height: 15vh;
    display: inline;
    float:left
}

#nav_more {
    height: 10vh;
}

img#nav_more {
    position: absolute;
    right: 2.5vh;
    top: 2.5vh;
}

.nav_button button {
background-color: #DB9C7B;
    width: 100%;
    height: 8vh;
    border: 0px #D24C4B solid;
    font-size: 20px;
    /* color: #577B57; */
    /* padding: 0px 16vw; */
} 

.nav_button button:hover {
    color: #577B57;
    /* padding: 0px 16vw; */
} 

.button_list {
position: fixed;
    top: 15vh;
    z-index: 1;
    width: 30vw;
    right: 0px;
    border: 1px #D24C4B solid;
    box-shadow: 0 2px 12px 0px #D24C4B;
    /* height: 50vh; */
}

.nav_button_pc {
    width: 25%;
}