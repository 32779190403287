.h-400 {
    height: 20vw;
}

.h-600 {
    height: 600px;
}

.w-1832 {
    width: 1832px;
}

.m-5 {
    margin: 5px;
}

.bold {
    font-weight: bold;
}


.centered {
    margin: auto;
}