@media (min-width:1000px){
.green{
    background-color: #577B57;
    height: 350px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 150px;
    display: grid;
    grid-template-columns: 50vw 30vw
}

.greenText{
    color:white;
    font-size: 32px;
    font-weight: 500;
    padding-top: 100px;
    margin: 0px;
}

.green_picture{
    width: 100%;
    height: 80%;
    overflow: hidden;
    object-fit: cover;
    border: solid black 3px;
    border-radius: 40px;
    /* margin: 45px; */
    position: relative;
    top: 10%;
}}

@media (max-width:1000px){
.green{
    background-color: #577B57;
    height: 50vh;
    margin-top: 0px;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 10vw;
    display: flex;
    flex-direction: column-reverse;
}

.greenText{
    color:white;
    font-size: 24px;
    font-weight: 500;
    width: 80vw;
}

.green_picture{
    width: 80vw;
    overflow: hidden;
  object-fit: cover;
  border: solid black 1px;
  border-radius: 10px;
}}



