@media (max-width:1000px){
    #icon{
        width: 30%;
        display: inline;
        position: relative;
        align-items: center;
    }

    .Footer {
    background-color: #DB9C7B;
    text-align: center;
    width: 100vw;
}
}

@media (min-width:1000px){
    #icon{
        width: 30%;
        display: inline;
        position: relative;
        align-items: center;
    }

    .Footer {
    background-color: #DB9C7B;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    text-align: center;

}
}



.Footer div {
}

.footer_link {
    display: inline;
    line-height: 100%;
}

div.footer_socials {
line-height: 100%;
    padding: 10px 10px;
    position: relative;
    margin-top: 6%
}

.footer_socials a {
    text-decoration: none;
    color: black;
}
