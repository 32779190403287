.galerij_element {
    background: #A9BDA1;
    width: 100%;
    /* height: 500px; */
    margin: 30px auto;
    box-shadow: 5px 4px 8px 0px #577B57;
}

.galerij_afbeelding {
    width: 100%;
    /* height: 101%; */
    object-fit: cover;
}


#go_back_button {
  width: 250px;
  height: max-content;
  border-radius: 10px;
  border: 0px;
  font-size: 24px;
  font-weight: 700;
  right: 300px;
  bottom: 200px;
  background-color: #D24C4B;
    scroll-behavior: smooth;
  color:black;
  text-decoration: none;
  text-align: center;
  padding: 20px;    
}


@media (min-width:1000px){
.galerij_page {
    width: 88vw;
    margin: auto 6vw;
}
.galerij_page_top {
display: grid;
    grid-template-columns: 55% 50%;
    column-gap: 5%;
}

.galerij_photo {
    margin: 0.2vw;
    height: 19.27761vw;
}
.galerij_list {
    display: grid;
    grid-template-columns: 28vw 28vw 28vw;
    margin-left: 6vw;
    margin-right: 6vw;
    text-align: center;
    column-gap: 2vw;
}

.galerij_jaar {
  position: relative;
  bottom: 10px;
}
}

@media (max-width:1000px){
.galerij_page {
    width: 94vw;
}

.galerij_page_top {
  text-align: center;
}
.galerij_photo {
    margin: 0.2vw;
    height: 62vw;
    max-width: 93vw;
}
.galerij_list {
    display: grid;
    text-align: center;
    width: 90vw;
    margin: auto;
}
}

/* Style the Image Used to Trigger the Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
    height: 80%;
    object-fit: contain;
}


/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}