html {
  scroll-behavior: smooth;
}

@media (min-width:1000px) {
.front {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: calc(100vh - 15vh);
    margin: 0px;
    background-position: 50% 40%;
    position: relative;
}







.frontPandemonium {
        background-color: #577B57;
        color: #EEC8A3;
        padding: 40px;
        width: max-content;
        border-radius: 10px;
        height: fit-content;
        box-shadow: 5px 4px 8px 0px #3e563e;
}

#komende_voorstelling_button {
    visibility: hidden;
    position:absolute;
    width: 250px;
    height: max-content;
    border-radius: 10px;
    border: 0px;
    font-size: 24px;
    font-weight: 700;
    right: 300px;
    bottom: 200px;
    background-color: #D24C4B;
      scroll-behavior: smooth;
    color:black;
    text-decoration: none;
    text-align: center;
    padding: 20px;    
}

.oker {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #D89C7B;
    display: grid;
    grid-template-columns: 50vw 50vw;
        vertical-align: middle;


}

#oker_image {
    width: 90%;
    margin-top: 6%;
    margin-bottom: 6%;
}

#oker_tekst{
  padding: 10vh 6vw;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

#komende_voorstelling{
    scroll-behavior: smooth;
    display: grid;
    grid-template-columns: 42vw 42vw;
    margin: auto 6vw;
    column-gap: 4vw;
}

#komende_voorstelling_tekst {

}

#komende_voorstelling_tekst h3{
    font-size: 3vw;
    margin-top: 0px;
}

#komende_voorstelling_tekst h4{
    font-size: 2vw;
}

#poster {
    width: 100%;
    height: 42vw;
}

#information {
    display: flex;
    flex-direction: row;           
    justify-content: center;
    margin: auto;
}



.information_block{
    background-color: #A9BDA1;
    width: 100%;
    justify-content: center;
    margin: 2vh 10vw;
    border-radius: 30px;
    box-shadow: 5px 4px 8px 0px #577B57;

}


}

@media (max-width:1000px){
#komende_voorstelling_button{
    visibility: hidden;
        position: absolute;
        bottom: 10px;
        right: 10vw;
        width: 30vw;
        height: max-content;
        border-radius: 5px;
        border: 0px;
        font-size: 15px;
        font-weight: 500;
        /* right: 300px; */
        background-color: #d24c4be8;
        scroll-behavior: smooth;
        color: black;
        text-decoration: none;
        text-align: center;
        padding: 10px;
}
.front {
    position: relative;
    background-image: url("../../src/Assets/Images/frontImage1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 40vh;
    margin: 0px;

    
}

.frontPandemonium {
background-color: #577b57c9;
        color: #EEC8A3;
        /* border: 5px solid #577b57; */
        padding: 5vw;
        width: max-content;
        border-radius: 15px;
        height: fit-content;
}

.oker {
    margin-top: 20px;
    padding-top: 10vw;
    background-color: #D89C7B;
    vertical-align: middle;
}

#oker_image {
    width: calc(80vw);;
    position: relative;
    margin: 10vw;
}

#oker_tekst{
    margin: 0px 10vw;
      display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

#poster {
    width: 100%;
    height: 100%;
    margin-top: 6vw
}

#komende_voorstelling{
    margin: 0px 10vw;
}

#information {
    display: flex;
    flex-direction: column;           
    justify-content: center;
    margin: 5vh auto;

}

.information_block{
    background-color: #A9BDA1;
    justify-content: center;
    margin: 2vh 10vw;
    border-radius: 30px;
    box-shadow: 5px 4px 8px 0px #577B57;

}

}


.information_block h4{
    font-size: 40px;
    text-align: center;
}

.information_title {
    border-bottom: 2px solid black;
    line-height: 0px;

}

.information_block p{
    text-align: center;
}

.information_warning{
    color: black;
    background-color: #ff0909;
    width: fit-content;
    margin: auto;
    border-radius: 10px;
}

#mapsLocation {
    background-color: #A9BDA1;
    padding: 10px;
    border-radius: 10px;
    border: 1px black solid;
}
#mapsLocation a{
    text-emphasis: none;
    text-decoration: none;
    color: black;
    padding: 10px;
    position: relative;
    top: -10px;
    font-size: 15px;
}

#mapsLogo {
width: 25px;
    position: relative;
    top: 9px;
    left: 8px;
}

#mapsBorder{
    width: fit-content;
    margin: auto;
}

#mapsLocation:hover{
    background-color: #577B57;
      transition: 0.5s;
}



#koop_kaartje_button{
background-color: #D89C7B;
    border-radius: 4px;
    /* background-color: #f4511e; */
    border: none;
    /* color: #FFFFFF; */
    text-align: center;
    font-size: 28px;
    padding: 20px;
    width: 250px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 5px; */
}

#koop_kaartje_button:hover{
    background-color: #D24C4B;
}


#koop_kaartje_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#koop_kaartje_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#koop_kaartje_button:hover span {
  padding-right: 25px;
}

#koop_kaartje_button:hover span:after {
  opacity: 1;
  right: 0;
}

.koop_kaartje_tekst{
    color: #DB9C7B;
}

.front_image_1 {
    background-image:  url("../../src/Assets/Images/Front/1.JPG");
}

.front_image_2 {
    background-image:  url("../../src/Assets/Images/Front/2.JPG");
}

.front_image_3 {
    background-image:  url("../../src/Assets/Images/Front/3.JPG");
}

.front_image_4 {
    background-image:  url("../../src/Assets/Images/Front/4.JPG");
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #EEC8A3;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: #577b57da;
}

.front_hero {
    display: flex;
    justify-content: center;
}