@media (min-width:1000px)
{
p {
  font-size: 1.5vw;
}

h1 {
  font-size: 6vw;
  font-weight: 500;
}
}
@media (max-width:1000px)
{
p {
  font-size: 18px;
}

h1 {
  font-size: 36px;
  font-weight: 500;
}
}


body {
  overflow-x: hidden;
}

.hidden {
  visibility: hidden;
  height: 0px;
}

.page {
  position: relative;
  top:15vh;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
