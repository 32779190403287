@media (min-width:1000px){
.crewList{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-rows: max-content;
    row-gap: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.cast_page{
    margin-left: 6vw;
    margin-right: 6vw;
}

.category{

    font-size: 48px;
    margin-bottom: 0px;
}
.person{
    background: #A9BDA1;
    width: 20vw;
    height: 100%;
    box-shadow: 5px 4px 8px 0px #577B57;
}

.person_picture{
    width: 20vw;
    height: 20vw;
    overflow: hidden;
  object-fit: cover;
  object-position: 50% 35%;
}

#Anne-Sophie_id{
    object-position: 50% 65% !important;
}

#Loeke_id{
    object-position: 50% 18% !important;
}
#Sylvester_id,
#Lora_id{
    object-position: 50% 6% !important;
}

#October_id,
#Annemoon_id{
    object-position: 50% 60% !important;
}


.name {
    line-height: 7px;
    font-size: 1.4vw;   
    }
.name{
    font-weight: 700;
}

.pronouns{
    color: #577B57;
    line-height: 5px;
    font-style: italic;
    font-size: 1.2vw;
}


.functie{
    line-height: 4px;
    font-size: 1.35vw;
}
}

@media (max-width:1000px){
.crewList{
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    text-align: center;
}
.category{
    margin-left:10vw;
    font-size: 32px;
    margin-bottom: 0px;
}
.person{
    background: #A9BDA1;
    margin-bottom: 5vw;
    display:grid;
    height: 40vh;
    grid-template-columns: 50% 50%;
    box-shadow: 5px 4px 8px 0px #577B57;
}
.person_picture{
    width: 100%;
    height: 40vh;
    overflow: hidden;
  object-fit: cover;
    object-position: 50% 25%;
}

#Loeke_id{
    object-position: 50% 18% !important;
}
#Sylvester_id,
#Lora_id{
    object-position: 50% 6% !important;
}

#October_id,
#Annemoon_id,
#Anne-Sophie_id{
    object-position: 50% 50% !important;
}


}


.name{
    font-weight: 700;
}

.pronouns{
    color: #577B57;
    line-height: 5px;
    font-style: italic;
    font-size: 20px;
}


.functie{
    line-height: 3px;
}
