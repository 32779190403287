@media (min-width:1000px){
.contact_page {
    display: grid;
    margin: 6vh 6vw;
    grid-template-columns: 44vw 44vw;
}
}

@media (max-width:1000px){
.contact_page {
    display: grid;
    margin: 6vh 6vw;
}
}


.contact_form {
    background-color: #A9BDA1;
    padding: 1vw;
    box-shadow: 5px 4px 8px 0px #577B57;

}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea,
input[type=email], input[type=tel] {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
.submit {
background-color: #D89C7B;
    border-radius: 4px;
    /* background-color: #f4511e; */
    border: none;
    /* color: #FFFFFF; */
    text-align: center;
    font-size: 28px;
    padding: 20px;
    width: 250px;
    transition: all 0.5s;
    cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
.submit:hover:enabled {
    background-color: #D24C4B;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
